
import { onDocumentLoad } from '~/utils';

export default {
  data() {
    return {
      scrollPosition: null,
      isAffShowed: false,
      sectionsPositions: {
        main: [],
        tablo: [],
        affiliator: [],
        news: [],
      },
    };
  },
  computed: {
    isHeaderLight() {
      return (this.scrollPosition > this.sectionsPositions.tablo[0] && this.scrollPosition < this.sectionsPositions.tablo[1]) ||
        (this.scrollPosition > this.sectionsPositions.news[0] && this.scrollPosition < this.sectionsPositions.news[1]);
    },
  },
  beforeMount () {
    onDocumentLoad(() => this.$bus.$emit('load-aff'));
  },
  destroyed() {
    if (this.$nuxt) this.$nuxt.$emit('route-changed');
  },
  head () {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    };
  },
};
